.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.center-section {
  text-align: center;
  margin-bottom: 40px;
}
.center-section h1 {
  font-size: 48px;
  font-weight: bold;
}
.center-section p {
  font-size: 16px;
}

.center-section button {
  font-size: 16px;
  border: none;
  background: #f8c229;
  color: #000;
  padding: 10px 50px;
  margin: 20px 0px;
}
.center-section button:hover {
  background: #ffd000;
}

.card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.hive-pack-card {
  max-width: 450px; /* Two cards in a row */
  width: 100%;
  margin: 20px 0px;
  padding: 20px;
  border: none;
}
.hive-pack-card img {
  width: 100%;
  object-fit: contain;
  margin: 30px 0;
}

.hive-pack-card h2 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 44px;
}

.hive-pack-card p {
  margin-top: 20px;
}

.hive-pack-card button {
  width: 100%;
  border: none;
  padding: 10px 20px;
}

.hive-pack-card button.yellow {
  background: #f8c229;
}

.hive-pack-card button.yellow:hover {
  background: #ffd000;
}

.hive-pack-card button.green {
  background: #8edf78;
}
.hive-pack-card button.green:hover {
  background-color: #45c921;
}
